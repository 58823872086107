import ClearIcon from '@mui/icons-material/Clear'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Thumbs, FreeMode, Keyboard } from 'swiper/modules'
import classNames from 'classnames'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { IconButton } from 'components/Button'
import { Image } from 'components/Image'
import { useBreakpoints } from 'hooks/useBreakpoints'

import 'swiper/css' // only core Swiper styles
import 'swiper/css/bundle' // all Swiper styles including all modules styles (like Navigation, Pagination, etc.)

import './styles.scss'

const onContextMenu = galleryDownloadIsAllowed => event => {
  if (galleryDownloadIsAllowed) {
    return true
  }
  event.preventDefault()
  event.stopPropagation()
  event.stopImmediatePropagation?.()
  return false
}

const renderSlideImage = ({
  image,
  toggleUI,
  isThumb,
  isSelected,
  galleryDownloadIsAllowed,
  galleryWatermark,
}) => {
  const photoSrc = galleryWatermark?.id && (galleryWatermark?.id === image.watermark?.watermarkId)
    ? image.watermark?.src
    : image.src
  const applyingWatermark = image.applyingWatermark || image.watermark?.isLoading
  const shouldBlurImage = applyingWatermark

  const thumbnails = isThumb
    ? [ { size: 'xs', media: '(min-width: 0px)' } ]
    : [
      { size: 'm', media: '(max-width: 600px)' },
      { size: 'l', media: '(min-width: 600px) and (max-width: 1920px)' },
      { size: 'xl', media: '(min-width: 1920px)' },
    ]

  return (
    <SwiperSlide key={image.id}>
      <Image
        className={classNames('carousel__slide__image', {
          'carousel__slide__image--thumb': isThumb,
          'carousel__slide__image--selected': isThumb && isSelected,
          'carousel__slide__image--non-selectable': !galleryDownloadIsAllowed,
          'carousel__slide__image--blurred': shouldBlurImage,
        })}
        thumbnailsSpecs={thumbnails}
        alt='carousel slide'
        src={photoSrc}
        onClick={isThumb ? undefined : toggleUI}
        generateThumbnailIfError
        onContextMenu={onContextMenu(galleryDownloadIsAllowed)}
        draggable={!!galleryDownloadIsAllowed}
        lazyLoaded
      />
    </SwiperSlide>
  )
}

const getSlidesPerView = ({ isMobileAndUp, isSmallTabletAndUp, isDesktopAndUp }) => {
  if (isDesktopAndUp) {
    return 12
  }

  if (isSmallTabletAndUp) {
    return 8
  }

  if (isMobileAndUp) {
    return 6
  }
}

export const Carousel = ({
  modalIsOpen,
  closeModal,
  onChangeIndex,
  imgs = [],
  imgsLength,
  initialPhotoId,
  actionButtons: customActionButtons,
  galleryDownloadIsAllowed,
  galleryWatermark,
}) => {
  const breakpoints = useBreakpoints()
  const dismissibleUI = breakpoints.isMobile || breakpoints.isSmallTablet
  const [activeIndex, setActiveIndex] = useState(0)
  const thumbsSwiper = useRef(null)
  const swiper = useRef(null)
  const swiperChangeTriggeredFromThumbsSwiper = useRef(null)
  const [carousellUIEnabled, setCarousellUIEnabled] = useState(true)
  const [thumbsSwiperIsHidden, setThumbsSwiperIsHidden] = useState(false)

  const setThumbsSwiper = newSwiper => {
    thumbsSwiper.current = newSwiper
  }

  const setSwiper = newSwiper => {
    swiper.current = newSwiper
  }

  useEffect(() => {
    if (!modalIsOpen) {
      thumbsSwiper.current = null
    }
  }, [modalIsOpen])

  const onSlideChange = useCallback(() => {
    const nextCurrentIndex = swiper.current.realIndex
    if (nextCurrentIndex >= 0) {
      setActiveIndex(nextCurrentIndex)
      if (swiperChangeTriggeredFromThumbsSwiper.current === null) {
        goToThumbSlide(nextCurrentIndex) // update thumbs swiper when the main swiper changes slide
      }
      swiperChangeTriggeredFromThumbsSwiper.current = null
      onChangeIndex && onChangeIndex(nextCurrentIndex)
    }
  }, [onChangeIndex])

  const toggleUI = () => {
    if (dismissibleUI) {
      setCarousellUIEnabled(state => !state)
    }
  }

  const onCloseModal = event => {
    closeModal()
    event.preventDefault()
    event.stopPropagation()
  }

  const goToSlide = (newIndex, triggeredFromThumbsSwiper) => {
    if (newIndex >= 0 && swiper.current) {
      // this will prevent the thumbs swiper to move when the main swiper was changed due to a click on the thumbs swiper
      if (triggeredFromThumbsSwiper) {
        swiperChangeTriggeredFromThumbsSwiper.current = true
      }
      // swiper.current.slideTo(initialSlide, 0) doesn't work very well
      const swiperInstance = swiper.current
      const translateValue = -swiperInstance.slides[newIndex].offsetLeft // Calculate the translate value for the slide
      swiperInstance.setTranslate(translateValue)
      swiperInstance.updateActiveIndex(newIndex) // Update the active index to sync Swiper's state
    }
  }

  const goToThumbSlide = newIndex => {
    if (newIndex >= 0 && thumbsSwiper.current) {
      // swiper.current.slideTo(initialSlide, 0) doesn't work very well
      const swiperInstance = thumbsSwiper.current

      // Get the slide element
      const targetSlide = swiperInstance.slides[newIndex]
      if (!targetSlide) { return }

      // Calculate the translate value to center the newIndex slide
      const slideWidth = targetSlide.offsetWidth
      const swiperWidth = swiperInstance.el.clientWidth
      const translateValue =
        -targetSlide.offsetLeft + (swiperWidth / 2) - (slideWidth / 2)

      // Update swiper translate and active index
      swiperInstance.setTranslate(translateValue)
      swiperInstance.updateActiveIndex(newIndex)
      swiperInstance.updateSlidesClasses() // Ensure slide classes (e.g., active) are updated
    }
  }

  // Use effect to update the carousel position when it's open
  // with cssMode, initialSlide prop doesn't work so we need to set the position like this
  useEffect(()=>{
    const initialSlide = imgs.findIndex(img => img.id === initialPhotoId)
    if (initialSlide >= 0) {
      goToSlide(initialSlide)
      goToThumbSlide(initialSlide)
      setActiveIndex(initialSlide)
    }
  }, [imgs, initialPhotoId])

  // Ideally we would use the native `thumbs={{ swiper: thumbsSwiper }}` in the main swiper, but after using `cssMode`
  // the transition when changing images in the thumbsSwiper was not good so we have to do it manually
  const onThumbsSwiperClick = thumbSwiper => {
    const newIndex = thumbSwiper.clickedIndex
    goToSlide(newIndex, true)
  }

  if (!modalIsOpen) {
    return null
  }

  const iconGetter = () => {
    return thumbsSwiperIsHidden ? <ExpandLessIcon fontSize='medium' /> : <ExpandMoreIcon fontSize='medium' />
  }

  const actionButtons = [
    ...(customActionButtons || []),
    {
      id: 'marked-photo-icon-2',
      iconGetter,
      onClick: () => setThumbsSwiperIsHidden(!thumbsSwiperIsHidden),
    },
  ]

  return (
    <section>
      <div>
        <div
          className={classNames('carousel__title', {
            'carousel__title--carousel-ui-enabled': carousellUIEnabled,
          })}
        >
          {`${activeIndex + 1}/${imgsLength}`}
        </div>
        <IconButton
          className={classNames('carousel__close-btn', {
            'carousel__close-btn--carousel-ui-enabled': carousellUIEnabled,
          })}
          color='default'
          size='small'
          aria-label='Close Carousel'
          onClick={onCloseModal}
        >
          <ClearIcon />
        </IconButton>
      </div>
      <div className='carousel__swiper-wrapper'>
        <div
          className={classNames('carousel__swiper', {
            'carousel__swiper--fullscreen': thumbsSwiperIsHidden || !carousellUIEnabled,
          })}
        >
          <Swiper
            modules={[Navigation, Pagination, Thumbs, FreeMode, Keyboard]}
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
              '--swiper-pagination-bullet-inactive-color': '#fff',
              '--swiper-navigation-size': '16px',
              '--swiper-navigation-sides-offset': '4px',
            }}
            className='carousel__swiper-slider'
            navigation={carousellUIEnabled}
            pagination={{
              'dynamicBullets': carousellUIEnabled,
            }}
            spaceBetween={10}
            // cssMode started to be used after we had perfomance problems in mobile with (not so) large galleries
            //  virtualization was also tried but it doesn't work good on mobile, having to many flickering changing images
            //  cssMode requires some manual logic but it's performant, uses browser native logic and allow us to do everything we need
            cssMode
            keyboard={{ enabled: true, onlyInViewport: false }}
            onSlideChange={onSlideChange}
            onSwiper={setSwiper}
          >
            {
              imgs.map(img => {
                return renderSlideImage({
                  image: img,
                  toggleUI,
                  isThumb: false,
                  isSelected: false,
                  galleryDownloadIsAllowed,
                  galleryWatermark,
                })
              })
            }
          </Swiper>
          {actionButtons && actionButtons.length > 0 && (
            <div
              className={classNames('carousel__swiper__action-buttons', {
                'carousel__swiper__action-buttons--carousel-ui-enabled': carousellUIEnabled,
              })}
            >
              {actionButtons.map(actionButton => {
                const isVisible = actionButton.visible ?? true
                if (!isVisible) {
                  return false
                }

                if (actionButton.componentGetter) {
                  return actionButton.componentGetter(imgs[activeIndex])
                }

                return (
                  <IconButton
                    key={actionButton.id}
                    className='carousel__swiper__action-button'
                    color='default'
                    size='small'
                    aria-label='Close Carousel'
                    onClick={() => actionButton.onClick(imgs[activeIndex])}
                  >
                    {actionButton.iconGetter(imgs[activeIndex])}
                  </IconButton>
                )
              })}
            </div>
          )}
        </div>
        <Swiper
          modules={[Thumbs]}
          className={classNames('carousel__swiper-thumbs', {
            'carousel__swiper-thumbs--carousel-ui-disabled': !carousellUIEnabled || thumbsSwiperIsHidden,
          })}
          onSwiper={setThumbsSwiper}
          slidesPerView={getSlidesPerView(breakpoints)}
          spaceBetween={4}
          watchSlidesProgress
          onClick={onThumbsSwiperClick}
          cssMode
        >
          {
            imgs.map((img, index) =>
              renderSlideImage({
                image: img,
                toggleUI,
                isThumb: true,
                isSelected: activeIndex === index,
                galleryDownloadIsAllowed,
                galleryWatermark,
              }))
          }
        </Swiper>
      </div>
    </section>
  )
}

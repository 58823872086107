import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import ShareIcon from '@mui/icons-material/Share'
import { Snackbar } from '@mui/material'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'

import { GalleryPhotoSkeleton } from 'scenes/Gallery/components/GalleryPhotosSkeleton'
import { ShareModal } from 'scenes/Gallery/components/ShareModal'
import { useBrowserIsWebview } from 'scenes/Gallery/hooks/useBrowserIsWebview'
import { useHandleGalleryArchive } from 'scenes/Gallery/hooks/useHandleGalleryArchive'
import { Carousel } from 'scenes/PackDetails/components/Carousel'

import { LoadingButton } from 'components/Button'
import { GalleryMasonryLayout } from 'components/GalleryMasonryLayout'
import { LinearProgress } from 'components/LinearProgress'
import { Link } from 'components/Link'
import { SimpleMessageModal } from 'components/Modal'
import { Text } from 'components/Text'

import { DownloadingIcon } from './components/DownloadingIcon'
import { FilterMenu } from './components/FilterMenu'
import { GalleryCover } from './components/GalleryCover'
import { GalleryImage } from './components/GalleryImage'

import { useGalleryPagination } from 'hooks/useGalleryPagination/useGalleryPagination'
import { useModal } from 'hooks/useModal'
import { useStopScroll } from 'hooks/useStopScroll'
import { useUrlQuery } from 'hooks/useUrlQuery'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import {
  fetchGallery as fetchGalleryAction,
  setGalleryMarkedPhotos as setGalleryMarkedPhotosAction,
  triggerAddWatermarkToGalleryPhotoJob as triggerAddWatermarkToGalleryPhotoJobAction,
  listenForPhotosWatermarkUpdate as listenForPhotosWatermarkUpdateAction,
} from 'store/galleries/actions'

import { ClientOfflineError, GalleryNotFoundError } from 'services/errorHandling'
import { getGalleryImageDownloadUrl } from 'services/firebaseServer'
import { t } from 'services/i18n'
import { createBackOfficeLink, createGalleryLink, subRoutesNames } from 'services/routingService'

import { logError } from 'utils/errorCapture'
import { downloadImage } from 'utils/images'

import './styles.scss'

const Gallery = () => {
  const { id } = useParams()
  const urlPhotoId = useUrlQuery('photo')
  const lastPhotoViewedInCarouselRef = useRef()
  const galleryHeaderRef = useRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const [uploadingMarkedPhotos, setUploadingMarkedPhotos] = useState({})
  const [filterMarkedPhotos, setFilterMarkedPhotos] = useState(false)
  const [downloadingPhotoIds, setDownloadingPhotoIds] = useState({})
  const [
    carouselModalIsOpen,
    openCarouselModal,
    closeCarouselModal,
    carouselModalContext,
  ] = useModal()
  const closingCarouselModal = useRef() // used to fix a bug where Slider onSlideChange is called after modal is closed, making the urlPhotoId in url change and open the modal again after closing
  const [shareModalIsOpen, openShareModal, closeShareModal, shareModalContext] = useModal()
  const [archiveConfirmationModalIsOpen, openArchiveConfirmationModal, closeArchiveConfirmationModal] = useModal()
  const [
    downloadingProgress,
    downloadinGallery,
    downloadingSnackbarOpen,
    setDownloadingSnackbarOpen,
    handleGalleryArchive,
  ] = useHandleGalleryArchive(id)

  useStopScroll(carouselModalIsOpen)

  const galleryIsLoading = useSelector(state => !!state.galleries.loading)
  const galleryIsLoadingExtraData = useSelector(state => !!state.galleries.fetchingExtraData)
  const galleryError = useSelector(state => state.galleries.error)
  const galleryFailedToLoad = !!galleryError
  const gallery = useSelector(state => id && state.galleries.data?.[id])
  const galleryIsAvailable = Boolean(gallery)
  const galleryUserIsAvailable = Boolean(gallery?.user)
  const galleryMarkedPhotos = useMemo(() => (gallery?.markedPhotos?.photoIds) || {}, [gallery])
  const galleryMarkedPhotosLength = Object.values(galleryMarkedPhotos)
    .filter(isMarkedPhoto => isMarkedPhoto) // remove unmarked one (global state can have unmarked photos as false)
    .length

  useDocumentTitle(`${gallery?.title}${gallery?.user?.name ? ` by ${gallery?.user?.name}` : ''} | PLOTU`)

  const photos = useMemo(() => gallery?.photos || {}, [gallery])
  const galleryPhotoKeys = useMemo(() => gallery?.photosLayout || [], [gallery])
  const shouldHideCover = gallery?.personalisation?.coverOptions.hideCover
  const galleryCoverPhotoId = gallery?.coverPhotoId
  const galleryPhotos = useMemo(() => galleryPhotoKeys
    .map(photoKey => photos[photoKey])
    .filter((photo, index) => {
      // hide cover photo if option is enabled
      if (photo?.id && shouldHideCover) {
        const isCoverPhoto = galleryCoverPhotoId
          ? photo.id === galleryCoverPhotoId
          : index === 0

        if (isCoverPhoto) {
          return false
        }
      }

      if (filterMarkedPhotos && photo?.id) {
        const isMarked = galleryMarkedPhotos[photo.id]
        return (filterMarkedPhotos === true && isMarked)
      }

      return !!photo?.id
    }), [
    galleryPhotoKeys,
    photos,
    shouldHideCover,
    galleryCoverPhotoId,
    filterMarkedPhotos,
    galleryMarkedPhotos,
  ])

  const photoSizes = useMemo(() => Object.values(galleryPhotos)
    .reduce((prev, currPhoto) => {
      return {
        ...prev,
        [currPhoto.id]: {
          height: currPhoto.height,
          width: currPhoto.width,
        },
      }
    }, {}), [galleryPhotos])

  const {
    galleryPhotosAreLoading,
    fetchingAllPhotos,
    photosLayoutAreAllFetched,
    hasPagesToLoadOnBottom,
    fetchGalleryPhotos,
    fetchAllGalleryPhotos,
    sentryRef,
  } = useGalleryPagination(gallery, id, galleryError, galleryIsLoading)

  const browserProps = useBrowserIsWebview()

  const fetchGallery = useCallback(
    galleryId => dispatch(fetchGalleryAction(galleryId, false)),
    [dispatch]
  )

  const setGalleryMarkedPhotos = useCallback(
    markedPhotos => {
      return dispatch(setGalleryMarkedPhotosAction(id, markedPhotos))
    },
    [id, dispatch]
  )

  const triggerAddWatermarkToGalleryPhotoJob = useCallback(
    (galleryId, watermarkId, photoId) =>
      dispatch(triggerAddWatermarkToGalleryPhotoJobAction(galleryId, watermarkId, photoId)),
    [dispatch]
  )

  const triggerListenForWatermarkChanges = useCallback(
    (galleryId, watermarkPhotoIDs, watermarkId) =>
      dispatch(listenForPhotosWatermarkUpdateAction(galleryId, watermarkPhotoIDs, watermarkId)),
    [dispatch]
  )

  useEffect(() => {
    if ((!galleryIsAvailable || !galleryUserIsAvailable) && !galleryIsLoading && !galleryIsLoadingExtraData) {
      fetchGallery(id)
    }
  }, [fetchGallery, id, galleryIsAvailable, galleryUserIsAvailable, galleryIsLoading, galleryIsLoadingExtraData])

  useEffect(() => {
    if (id && !galleryIsLoading && gallery && !gallery?.photos && !galleryFailedToLoad && !galleryPhotosAreLoading) {
      // TODO: Adapt the fetchGalleryPhotos reducer to not depend on the gallery being present in the store so this can be truly async

      // if gallery has a specific photo context that we need to show, we need to load all photos and open the carousel right after
      if (urlPhotoId) {
        fetchAllGalleryPhotos(id)
        return
      }
      fetchGalleryPhotos(id, 0)
    }
  }, [
    id,
    gallery,
    urlPhotoId,
    fetchAllGalleryPhotos,
    fetchGalleryPhotos,
    galleryIsLoading,
    galleryPhotosAreLoading,
    galleryFailedToLoad,
  ])

  useEffect(() => {
    if (urlPhotoId && photos?.[urlPhotoId] && !carouselModalIsOpen && closingCarouselModal.current !== true) {
      closingCarouselModal.current = false
      openCarouselModal(urlPhotoId)
    }
  }, [
    carouselModalIsOpen,
    openCarouselModal,
    photos,
    urlPhotoId,
  ])

  useEffect(() => {
    // make sure scroll is on top when loading even whe page is simply reloaded (and scroll top is not triggered in ScrollBoundary component)
    setTimeout(() => window.scrollTo(0, 0), 0)
  }, [])

  const browserSupportMessage = useMemo(() => {
    let exampleApplication = 'Instagram'
    if (browserProps.userAgent) {
      switch (true) {
        case (browserProps.userAgent.includes('fban')):
          exampleApplication = 'Facebook'
          break
        case (browserProps.userAgent.includes('instagram')):
          exampleApplication = 'Instagram'
          break
        default:
          exampleApplication = 'Instagram'
      }
    }
    return `Parece que estás a usar um browser sem suporte.\n\nSe estiveres a usar um browser interno de uma aplicação, como o ${exampleApplication}, por favor abre a galeria no teu browser nativo (por exemplo no ${browserProps.os === 'IOS' ? 'Safari' : 'Chrome'})`
  }, [browserProps])

  const toggleDownloadingSnackbarOpen = () => {
    setDownloadingSnackbarOpen(false)
  }

  const onPhotoMarkClick = (galleryPhoto, deleteMarkedPhoto) => () => {
    if (!deleteMarkedPhoto && gallery.markedPhotos?.markedPhotosMaxLimit != null &&
      galleryMarkedPhotosLength >= gallery.markedPhotos.markedPhotosMaxLimit
    ) {
      return window.alert(`Apenas podes marcar ${gallery.markedPhotos?.markedPhotosMaxLimit} fotos!`)
    }

    const newMarkedPhotoIds = {
      ...(galleryMarkedPhotos || {}),
      [galleryPhoto.id]: !deleteMarkedPhoto,
    }

    setUploadingMarkedPhotos({
      ...uploadingMarkedPhotos,
      [galleryPhoto.id]: UPLOAD_STATUS.UPLOADING,
    })

    setGalleryMarkedPhotos(newMarkedPhotoIds)
      .then(() => {
        setUploadingMarkedPhotos({
          ...uploadingMarkedPhotos,
          [galleryPhoto.id]: UPLOAD_STATUS.NOT_UPLOADED,
        })
      })
  }

  const onFilterChange = (_filterKey, value) => {
    setFilterMarkedPhotos(value)
  }

  const openCarousel = photoId => () => {
    closingCarouselModal.current = false
    if (!photosLayoutAreAllFetched && !fetchingAllPhotos) {
      fetchAllGalleryPhotos(id)
    }
    openCarouselModal(photoId)
  }

  const closeCarousel = () => {
    history.replace(createGalleryLink(id, null, true, ['photo']))
    closingCarouselModal.current = true
    closeCarouselModal()
  }

  // when the carousel is closed, let's scroll into the last
  useEffect(() => {
    if (!carouselModalIsOpen && lastPhotoViewedInCarouselRef.current) {
      lastPhotoViewedInCarouselRef.current.scrollIntoView({ behavior: 'instant', block: 'center' })
    }
  }, [carouselModalIsOpen, lastPhotoViewedInCarouselRef])

  const onCarouselChangeIndex = newImageIndex => {
    if (closingCarouselModal.current === true) {
      return
    }
    const newImageId = galleryPhotos[newImageIndex]?.id
    if (newImageId && newImageId !== urlPhotoId) {
      history.replace(createGalleryLink(id, { photo: newImageId }, true))
    }
  }

  const iconGetter = photo => {
    const isMarked = galleryMarkedPhotos[photo.id]
    return isMarked ? <BookmarkIcon fontSize='medium' /> : <BookmarkBorderIcon fontSize='medium' />
  }

  const shareIconGetter = () => <ShareIcon fontSize='medium' />

  const getDownloadingLabel = () => {
    const totalNrOfPhotos = galleryPhotoKeys.length

    if (downloadingProgress > 80) {
      return (
        <p className='gallery__snackbar__label'>
          Só mais um pouco, estamos quase...
        </p>
      )
    }

    if (totalNrOfPhotos > 300) {
      return (
        <p className='gallery__snackbar__label'>
          {`${totalNrOfPhotos} fotos? Vai demorar algum tempo a preparar!`}
        </p>
      )
    }

    if (totalNrOfPhotos > 100) {
      return (
        <p className='gallery__snackbar__label'>
          {`${totalNrOfPhotos} fotos. Pode demorar algum tempo a preparar!`}
        </p>
      )
    }

    if (totalNrOfPhotos > 50) {
      return (
        <p className='gallery__snackbar__label'>
          {`${totalNrOfPhotos} fotos a serem preparadas num instante!`}
        </p>
      )
    }

    return null
  }

  const downloadPhoto = useCallback(photo => {
    let photoName = photo.name
    if (photoName === undefined) {
      try {
        photoName = `${photo.id}.${photo.src.split('.').pop().split('?')[0]}` // id + photo extension
      } catch (error) {
        logError(error)
      }
    }

    setDownloadingPhotoIds({
      ...downloadingPhotoIds,
      [photo.id]: true,
    })

    getGalleryImageDownloadUrl(id, photo.id, photo.src)
      .then(imageDownloadUrl => {
        return downloadImage(imageDownloadUrl, photo.name, () => {
          setDownloadingPhotoIds(prevDownloadingPhotoIds => {
            const downloadingPhotoIdsCopy = { ...prevDownloadingPhotoIds }
            delete downloadingPhotoIdsCopy[photo.id]
            return downloadingPhotoIdsCopy
          })
        })
      })
      .catch(error => {
        logError(error, 'Houve um problema a fazer o download da image. Por favor tenta novamente', true)
      })

  }, [downloadingPhotoIds, id])

  const downloadComponentGetter = useCallback(photo => (
    <DownloadingIcon
      onClick={() => downloadPhoto(photo)}
      isDownloading={downloadingPhotoIds[photo.id]}
    />
  ), [downloadPhoto, downloadingPhotoIds])

  const handleGalleryArchiveConfirmation = () => {
    if (browserProps.browserIsWebview) {
      openArchiveConfirmationModal()
    } else {
      handleGalleryArchive()
    }
  }

  if (galleryFailedToLoad) {
    if (galleryError?.code === 'unavailable') {
      throw new ClientOfflineError('Não conseguimos chegar ao servidor. Verifica que estás conetado à internet e tenta novamente')
    }

    if (galleryError?.code === 'permission-denied') {
      throw new GalleryNotFoundError(galleryError)
    }

    throw new Error(galleryError)
  }

  const galleryLink = createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES, gallery?.id)

  return (
    <div className='gallery'>
      {!galleryIsLoading && !gallery?.sharingOptions?.shared && (
        <div className='gallery__private-gallery'>
          <Text type='body'>
            {t('gallery.private_gallery_header')}
            <Link underlined href={galleryLink}>aqui</Link>
          </Text>
        </div>
      )}
      <div className='gallery__cover'>
        <GalleryCover
          gallery={gallery}
          contentRef={galleryHeaderRef}
        />
      </div>
      {gallery?.downloadIsAllowed && (
        <div className='gallery__download-cta'>
          <LoadingButton
            loading={downloadinGallery}
            loadingPosition='center'
            disabled={!gallery}
            onClick={handleGalleryArchiveConfirmation}
            variant='outlined'
          >
            Descarregar todas as fotos
          </LoadingButton>
        </div>
      )}
      <div className='gallery__header' ref={galleryHeaderRef}>
        {galleryPhotoKeys.length > 0 && (
          <div className='gallery__header__nr-photos-label'>
            {filterMarkedPhotos ? `${galleryMarkedPhotosLength} de ${galleryPhotoKeys.length} fotos` : `${galleryPhotoKeys.length} fotos`}
          </div>
        )}
        {gallery?.markedPhotos && gallery.markedPhotos.markedPhotosAreAllowed && (
          <div className='gallery__header__marked-photos-sub-menu'>
            <p className='gallery__header__marked-photos-value'>
              {`Fotos marcadas: ${galleryMarkedPhotosLength}${gallery.markedPhotos.markedPhotosMaxLimit > 0 ? `/${gallery.markedPhotos.markedPhotosMaxLimit}` : ''}`}
            </p>
            <FilterMenu onFilterChange={onFilterChange} />
          </div>
        )}
      </div>
      {/* TODO: Add "Submeter fotos marcadas" CTA to notify photographer about the marked photos.
      Do not allow to submit when marked photos are more than limit */}
      <GalleryMasonryLayout
        photoSizes={photoSizes}
        layoutOptions={gallery?.personalisation?.layoutOptions}
      >
        {galleryPhotos.map(galleryPhoto => {
          if (!galleryPhoto) {
            logError(`Photo not available in gallery ${id}`)
            return null
          }
          const isMarked = galleryMarkedPhotos[galleryPhoto.id]
          const isUploading = uploadingMarkedPhotos[galleryPhoto.id] === UPLOAD_STATUS.UPLOADING
          const isDownloading = downloadingPhotoIds[galleryPhoto.id]

          return (
            <div key={galleryPhoto.id} ref={galleryPhoto.id === urlPhotoId ? lastPhotoViewedInCarouselRef : null} className='gallery__photo'>
              <GalleryImage
                galleryPhoto={galleryPhoto}
                urlPhotoId={urlPhotoId}
                isMarked={isMarked}
                gallery={gallery}
                isUploading={isUploading}
                isDownloading={isDownloading}
                downloadPhoto={downloadPhoto}
                onPhotoMarkClick={onPhotoMarkClick}
                openCarousel={openCarousel}
                triggerAddWatermarkToGalleryPhotoJob={
                  () => triggerAddWatermarkToGalleryPhotoJob(gallery.id, gallery.watermark?.id, galleryPhoto)
                }
                triggerListenForWatermarkChanges={
                  () => triggerListenForWatermarkChanges(gallery.id, [galleryPhoto.id], gallery.watermark?.id)
                }
                rectAngles={gallery?.personalisation?.layoutOptions?.rectAngles}
              />
            </div>
          )
        })}
        {/* Show the gallery photos sekeleton to show that next photos are loading.
          This will also serve as the sentry to react-infinite-scroll-hook
          When gallery is in the initial loading, this skeletons will also appear
        */}
        {((!photosLayoutAreAllFetched && hasPagesToLoadOnBottom) || galleryIsLoading) && ['gallery-photo-skeleton-0', 'gallery-photo-skeleton-1', 'gallery-photo-skeleton-2', 'gallery-photo-skeleton-3', 'gallery-photo-skeleton-4', 'gallery-photo-skeleton-5']
          .map((id, index) => (
            <GalleryPhotoSkeleton
              key={id}
              ref={index === 0 && !galleryIsLoading ? sentryRef : null}
              rectAngles={gallery?.personalisation?.layoutOptions?.rectAngles}
            />
          ))}
      </GalleryMasonryLayout>
      <Carousel
        imgs={galleryPhotos}
        imgsLength={galleryPhotoKeys.length}
        modalIsOpen={carouselModalIsOpen}
        closeModal={closeCarousel}
        initialPhotoId={carouselModalContext}
        onChangeIndex={onCarouselChangeIndex}
        actionButtons={[
          {
            id: 'marked-photo-icon',
            visible: gallery?.markedPhotos?.markedPhotosAreAllowed,
            iconGetter,
            onClick: photo => onPhotoMarkClick(photo, galleryMarkedPhotos[photo.id])(),
            isEnabled: true,
          },
          {
            id: 'share-photo-icon',
            iconGetter: shareIconGetter,
            onClick: photo => openShareModal(photo.id),
            isEnabled: true,
          },
          {
            id: 'download-photo-icon',
            componentGetter: downloadComponentGetter,
            isEnabled: !!gallery?.downloadIsAllowed,
          },
        ].filter(actionButton => actionButton.isEnabled)}
        galleryDownloadIsAllowed={gallery?.downloadIsAllowed}
        galleryWatermark={gallery?.watermark}
      />
      <ShareModal open={shareModalIsOpen} closeModal={closeShareModal} imageId={shareModalContext} />
      <SimpleMessageModal
        open={archiveConfirmationModalIsOpen}
        closeModal={closeArchiveConfirmationModal}
        modalTitle=''
        message={browserSupportMessage}
        secondaryActionButtonText='Tentar mesmo assim'
        secondaryActionButtonVariant='text'
        secondaryActionButtonColor='default'
        onSecondaryActionButton={handleGalleryArchive}
        actionButtonText='Ok'
        actionButtonColor='primary'
        onActionButton={closeArchiveConfirmationModal}
      />
      <Snackbar
        onClose={toggleDownloadingSnackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={downloadingSnackbarOpen}
      >
        <div className='gallery__snackbar'>
          <p className='gallery__snackbar__label gallery__snackbar__label--bold'>A preparar a galeria nos nossos servidores...</p>
          {getDownloadingLabel()}
          <LinearProgress value={downloadingProgress} />
        </div>
      </Snackbar>
    </div>
  )
}

export default Gallery
